export default {
    'weddings': [
      {
        'id': '1',
        'budget': '32,000'
      },
      {
        'id': '2',
        'budget': '45000'
      }
    ],
    'guests': [
        {
          'id': '1',
          'name': 'Li Shang',
          'email': 'Be4Man@gmail.com',
          'eventId': '1'
        },
        {
          'id': '2',
          'name': 'Zhu Fa',
          'email': 'WarriorZu@gmail.com',
          'eventId': '1'
        }
    ],
    'expenses': [
      {
          'id': '1',
          'expense': `Po's Dumplings`,
          'note': 'Catering: Pork, beef, chicken',
          'price': '1000',
          'eventId': '1'
      },
      {
          'id': '2',
          'expense': `Emporer's Palace`,
          'note': 'Venue for Tea Ceremony',
          'price': '2500',
          'eventId': '1'
      },
      {
          'id': '3',
          'expense': 'Mushu Pork Roast',
          'note': 'Gifts',
          'price': '5000',
          'eventId': '1'
      }
    ]
  }